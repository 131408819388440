import { OrganizationalFarm, Location } from '@infarm/faas-middleware-types';
import { getToken, withAuth } from '@infarm/auth';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';

const env = (globalThis as any).env || {};
export const getMiddlewareApolloClient = (): ApolloClient<any> => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          organizationalFarms: {
            keyArgs: ['filter', 'farmFilter'],
            merge(existing, incoming, { args }) {
              let edges: OrganizationalFarm[] = [];
              let error;
              if (!existing || !args?.cursor) return incoming;
              if (existing.pageInfo?.endCursor === args.cursor)
                edges = [...existing.edges, ...incoming.edges];
              else error = 'Failed to load organizationalFarms, please try again.';

              return {
                ...incoming,
                edges,
                error
              };
            }
          },
          organizationalFarm: {
            merge: false
          },
          locations: {
            keyArgs: ['filter', 'farmFilter'],
            merge(existing, incoming, { args }) {
              let edges: Location[] = [];
              let error;
              if (!existing || !args?.cursor) return incoming;
              if (existing.pageInfo?.endCursor === args.cursor)
                edges = [...existing.edges, ...incoming.edges];
              else error = 'Failed to load locations, please try again.';

              return {
                ...incoming,
                edges,
                error
              };
            }
          },
          location: {
            merge: false
          }
        }
      }
    }
  });

  const user = getToken();

  const faasWsLink = new WebSocketLink(
    new SubscriptionClient(env.FAAS_WS_MIDDLEWARE, {
      connectionParams: {
        authorization: `Bearer ${user?.accessToken}`
      }
    })
  );

  const faasHttpLink = new HttpLink({
    uri: env.FAAS_MIDDLEWARE,
    fetch: withAuth(fetch)
  });

  const faasLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    faasWsLink,
    faasHttpLink
  );

  return new ApolloClient({
    link: faasLink,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  });
};
