import { getUser, JwtUser, userHasRole } from '@infarm/auth';

export const maintenanceExpert = 'maintenance_expert';
export const superAdmin = 'super_admin';
export const maintenanceSupervisor = 'maintenance_supervisor';
export const cropScientist = 'crop_scientist';

export const userCanAssignFarmId = (): boolean => {
  const user: JwtUser = getUser() as JwtUser;
  return userHasRole('super_admin', user) || userHasRole('maintenance_supervisor', user);
};

export const userCanAddInStoreRecipes = (): boolean => {
  const user: JwtUser = getUser() as JwtUser;
  return userHasRole('super_admin', user) || userHasRole('crop_scientist', user);
};

export const userHasCustomPermissions = (roles: string[], user: JwtUser) =>
  [superAdmin, ...roles].some(role => userHasRole(role, user));

export const userCanEditRecipes = () => {
  const user = getUser();
  return (
    userHasRole(superAdmin, user!) ||
    userHasRole(maintenanceSupervisor, user!) ||
    userHasRole(maintenanceExpert, user!) ||
    userHasRole(cropScientist, user!)
  );
};
