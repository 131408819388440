import gql from 'graphql-tag';

export const ORGANIZATIONAL_FARMS = gql`
  fragment OrganizationalFarmsFragment on OrganizationalFarm {
    name
    uuid
    model
    physicalFarmModel
    isGen2
    serialNumber
    operationalFarm {
      serialNumber
      name
      model
      uuid
    }
  }
`;

export const SET_ACRE_CONFIGURATION = gql`
  mutation setAcreV2Config($input: FarmConfigInput!) {
    setFarmConfigurationV2(input: $input) {
      configId
      farmUuid
      status
    }
  }
`;

export const GET_FARM_CONFIGURATION_HISTORY = gql`
  query farmHistory($farmUuid: String!) {
    farmConfigHistory(farmUuid: $farmUuid) {
      configId
      state
      createdAt
      updatedAt
    }
  }
`;

export const GET_ACRE_CONFIG = gql`
  query getAcreV2GlobalConfig($serialNumber: String!) {
    farmConfigurationV2(serialNumber: $serialNumber) {
      serialNumber
      config {
        ... on AcreConfigV2 {
          timezone
          global {
            co2 {
              setPoint
              dosingTimeInSeconds
              settlingTimeInSeconds
            }
            wcs {
              ph
              ec
              temperature
            }
            crac {
              temperature
              humidity
              unitType
              unitLocation
              airflowVolume
            }
          }
          perPlantGroup {
            plantGroup
            stage2 {
              irrigations {
                schedule {
                  frequency
                  interval
                  runsPerDay
                }
                cycleDuration
                soakingDurationInSeconds
              }
              darkIrrigations {
                cycleDuration
                soakingDurationInSeconds
                runsPerNight
              }
              light {
                intensity
                cycleDuration
              }
            }
            stage3 {
              irrigations {
                schedule {
                  frequency
                  interval
                  runsPerDay
                }
                cycleDuration
                soakingDurationInSeconds
              }
              darkIrrigations {
                cycleDuration
                soakingDurationInSeconds
                runsPerNight
              }
              light {
                intensity
                cycleDuration
              }
            }
          }
        }
      }
    }
  }
`;

export const WATCH_FARM_STATE = gql`
  subscription farmState($serialNumber: String!) {
    farmState(serialNumber: $serialNumber) {
      state
      isOnline
    }
  }
`;

export const GET_FARM_STATE = gql`
  query getFarmState($farmUuid: String!, $includeSubsystemsState: Boolean) {
    farmState(farmUuid: $farmUuid, includeSubsystemsState: $includeSubsystemsState) {
      farmUuid
      isOnline
      state
      subsystemsState {
        instore {
          wcs {
            ecDosing
            phDosing
          }
        }
      }
    }
  }
`;

export const FETCH_ORGANIZATIONAL_FARMS = gql`
  ${ORGANIZATIONAL_FARMS}
  query getOrganizationalFarms($filter: OrganizationalFarmFilter, $cursor: String, $limit: Int) {
    organizationalFarms(filter: $filter, cursor: $cursor, limit: $limit) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ...OrganizationalFarmsFragment
        location {
          name
          uuid
          locationGroup {
            name
            uuid
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONAL_FARM_QUERY = gql`
  query OrganizationalFarm($serialNumber: String!) {
    organizationalFarm(serialNumber: $serialNumber) {
      name
      uuid
      model
      physicalFarmModel
      isGen2
      serialNumber
      location {
        name
        uuid
        locationGroup {
          name
          uuid
        }
      }
    }
  }
`;

export const GET_USER_ACTIONS = gql`
  query UserActions($farmId: String!) {
    userActions(farmId: $farmId) {
      farmUuid
      actions {
        section
        user
        updatedAt
        status
        isRecipe
        plantGroup
        isGlobalRecipe
      }
    }
  }
`;

export const SEND_FARM_COMMAND = gql`
  mutation sendCommand($input: FarmCommandInput!) {
    sendFarmCommand(farmCommandInput: $input) {
      commandId
      farmUuid
      state
    }
  }
`;

export const FETCH_LOCATIONS = gql`
  query fetchLocations(
    $cursor: String
    $filter: LocationFilter
    $farmFilter: OrganizationalFarmFilter
  ) {
    locations(cursor: $cursor, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        uuid
        name
        category
        locationGroup {
          name
          uuid
        }
        organizationalFarms(filter: $farmFilter) {
          name
          uuid
          model
          physicalFarmModel
          isGen2
          serialNumber
          operationalFarm {
            uuid
            name
          }
        }
      }
    }
  }
`;

export const GET_INSTORE_V1_CONFIG = gql`
  query FarmConfigurationV2($serialNumber: String!) {
    farmConfigurationV2(serialNumber: $serialNumber) {
      serialNumber
      farmType
      config {
        ... on InstoreConfigV1 {
          timezone
          global {
            tankRefresh {
              schedule {
                timeOfDay
                on {
                  dayOfWeek
                  repeatInterval
                }
              }
            }
            instoreCrac {
              airflowSpeed
              heatingCapacity
              humidity
              temperature
              temperatureNight
            }
          }
          perPlantGroup {
            plantGroup
            irrigation {
              ph
              phDelta
              ec
              ecDelta
            }
            light {
              intensity
              schedule {
                timeOfDay
                on {
                  dayOfWeek
                  repeatInterval
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_INSTORE_V2_CONFIG = gql`
  query FarmConfigurationV2($serialNumber: String!) {
    farmConfigurationV2(serialNumber: $serialNumber) {
      serialNumber
      farmType
      config {
        ... on InstoreConfigV2 {
          timezone
          global {
            tankRefresh {
              schedule {
                timeOfDay
                on {
                  dayOfWeek
                  repeatInterval
                }
              }
            }
            instoreCrac {
              airflowSpeed
              heatingCapacity
              humidity
              temperature
              temperatureNight
            }
          }
          perPlantGroup {
            plantGroup
            irrigation {
              ph
              phDelta
              ec
              ecDelta
              schedule {
                timeOfDay
                periodMinutes
                on {
                  dayOfWeek
                  repeatInterval
                }
              }
              soakingDurationInSeconds
            }
            light {
              intensity
              schedule {
                timeOfDay
                on {
                  dayOfWeek
                  repeatInterval
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACRE_CONFIG_V2_QUERY = gql`
  query FarmConfigurationV2($serialNumber: String!) {
    farmConfigurationV2(serialNumber: $serialNumber) {
      serialNumber
      farmType
      config {
        ... on AcreConfigV2 {
          timezone
          global {
            co2 {
              setPoint
              dosingTimeInSeconds
              settlingTimeInSeconds
            }
            wcs {
              ec
              ph
              temperature
            }
            crac {
              temperature
              humidity
              unitLocation
              unitType
              airflowVolume
            }
          }
          perPlantGroup {
            plantGroup
            stage2 {
              irrigations {
                soakingDurationInSeconds
                cycleDuration
                schedule {
                  frequency
                  interval
                  runsPerDay
                }
              }
              darkIrrigations {
                runsPerNight
                cycleDuration
                soakingDurationInSeconds
              }
              light {
                intensity
                cycleDuration
              }
            }
            stage3 {
              irrigations {
                soakingDurationInSeconds
                cycleDuration
                schedule {
                  frequency
                  interval
                  runsPerDay
                }
              }
              darkIrrigations {
                runsPerNight
                cycleDuration
                soakingDurationInSeconds
              }
              light {
                intensity
                cycleDuration
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACRE_FARM_HARDWARE_CONFIG_WCS = gql`
  query getHardwareConfiguration($serialNumber: String!) {
    hardwareConfiguration(serialNumber: $serialNumber) {
      serialNumber
      acre {
        wcs {
          id
          manufacturer
          isControlled
          version
        }
      }
    }
  }
`;

export const GET_WCS_QUERY = gql`
  query getWaterConditioningById($wcsId: String!) {
    waterConditioningSystem(wcsId: $wcsId) {
      id
      controllingFarmUuid
      farms
    }
  }
`;

export const GET_ACRE_LOCATIONS = gql`
  query GetLocations($cursor: String, $filter: LocationFilter, $limit: Int) {
    locations(cursor: $cursor, filter: $filter, limit: $limit) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        uuid
        name
        category
        locationGroup {
          name
        }
        operationalFarms(farmModel: [ACRE]) {
          uuid
        }
      }
    }
  }
`;

export const GET_ALL_ACRE_LOCATIONS = gql`
  query GetLocations($filter: AllLocationsFilter) {
    allLocations(filter: $filter) {
      uuid
      name
      category
      locationGroup {
        name
      }
      numberOfOrganizationalFarmAcresForHub
    }
  }
`;

export const GET_ALL_FARMS_FOR_LOCATION = gql`
  ${ORGANIZATIONAL_FARMS}
  query Location($uuid: ID!) {
    location(uuid: $uuid) {
      uuid
      name
      timezone
      category
      locationGroup {
        name
        uuid
      }
      farms: organizationalFarms {
        ...OrganizationalFarmsFragment
        location {
          name
          uuid
          locationGroup {
            name
            uuid
          }
        }
      }
    }
  }
`;
