import { FarmCommandPart, FarmStatus, UserAction } from '@infarm/faas-middleware-types';

import EnteringMaintenanceIcon from '../../assets/farm-commands/entering_maintenance.svg';
import ExitingMaintenanceIcon from '../../assets/farm-commands/exiting_maintenance.svg';
import DosingEnabled from '../../assets/farm-commands/dosing_enabled.svg';
import DosingDisabled from '../../assets/farm-commands/dosing_disabled.svg';
import { CardCopy } from './types';

export const getCardCopy = (
  part: FarmCommandPart,
  isActivated: boolean,
  state: FarmStatus
): CardCopy => {
  if (part === FarmCommandPart.Maintenance) {
    const title = 'Maintenance Mode';
    const icon = isActivated ? ExitingMaintenanceIcon : EnteringMaintenanceIcon;
    const button = isActivated ? 'Exit' : 'Enter Maintenance Mode';
    const status = state === FarmStatus.Maintenance ? 'Enabled' : 'Disabled';
    return {
      title,
      icon,
      button,
      status
    };
  }
  const title = `${part === FarmCommandPart.EcDosing ? 'EC' : 'pH'} auto-dosing`;
  const icon = isActivated ? DosingDisabled : DosingEnabled;
  const button = isActivated ? 'Turn on' : 'Turn off';
  const status = isActivated ? 'Bypassed' : 'Enabled';
  return {
    title,
    icon,
    button,
    status
  };
};

export const formattedDate = (updatedAt: string): string => {
  const date = new Date(Number(updatedAt));

  const formatOptions: any = {
    dateStyle: 'long',
    timeStyle: 'long'
  };
  const parts: Intl.DateTimeFormatPart[] = new Intl.DateTimeFormat(
    'en-US',
    formatOptions
  ).formatToParts(date);
  const month = parts.find(part => part.type === 'month')?.value;
  const day = parts.find(part => part.type === 'day')?.value;
  const hour = parts.find(part => part.type === 'hour')?.value;
  const minute = parts.find(part => part.type === 'minute')?.value;
  const dayPeriod = parts.find(part => part.type === 'dayPeriod')?.value;
  const timeZoneName = parts.find(part => part.type === 'timeZoneName')?.value;
  return `${month} ${day} at ${hour}:${minute}${dayPeriod} (${timeZoneName})`;
};

export const lastUpdatedRecipeFormat = (userAction?: UserAction) => {
  return userAction
    ? `Last edit on ${formattedDate(userAction.updatedAt)} by ${userAction.user}`
    : '';
};
