import { DsRow } from '@infarm/design-system-react';
import styled from 'styled-components';

export const DetailsContaining = styled.div`
  margin: 0px;
`;

export const RecipeHeading = styled(DsRow)`
  color: #666666;
  margin: 24px 24px 8px 24px;
`;
