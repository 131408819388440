import { useQuery } from '@apollo/client';
import { DsRow } from '@infarm/design-system-react';
import { FarmConfigurationV2, AcreConfigV2, Wcsv2 } from '@infarm/faas-middleware-types';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  GET_ACRE_CONFIG_V2_QUERY,
  GET_ACRE_FARM_HARDWARE_CONFIG_WCS,
  GET_WCS_QUERY
} from '../../../lib/queries';
import { RecipeHeading } from './styled-components';

type AcreWaterCardProps = {
  farmConfig: FarmConfigurationV2;
  setModalData?: Function;
};

// WcsLoad describes and tracks the name of the WCS loading scheme:
// 1. load the farm's HARDWARE config to get the name of the WCS
// 2. load the WCS config to let us know what the controlling farm is
// 3. load the FARM's configuration
enum WcsLoad {
  STOP,
  HARDWARE,
  WCS,
  FARM
}

export const AcreWaterDetail: FunctionComponent<AcreWaterCardProps> = ({
  farmConfig,
  setModalData
}) => {
  const [displayError, setDisplayError] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [wcsId, setWcsId] = useState<string>();
  const [controllingFarmId, setControllingFarmId] = useState<string>();
  const [loadStep, setLoadStep] = useState<WcsLoad>();
  const [wcsData, setWcsData] = useState<Wcsv2 | null>();

  const ph = wcsData?.ph;
  const ec = wcsData?.ec;

  useEffect(() => {
    if (!!(farmConfig.config as AcreConfigV2)?.global?.wcs) {
      const scopedWCS = (farmConfig.config as AcreConfigV2)?.global?.wcs;
      setWcsData(scopedWCS);
    } else {
      setDisplayLoading(true);
      setLoadStep(WcsLoad.HARDWARE);
    }
  }, [farmConfig]);

  useQuery(GET_ACRE_FARM_HARDWARE_CONFIG_WCS, {
    variables: { serialNumber: farmConfig?.serialNumber },
    skip: loadStep !== WcsLoad.HARDWARE,
    onCompleted: data => {
      const acreWcsId = data?.hardwareConfiguration?.acre?.wcs?.id;
      if (acreWcsId) {
        setWcsId(acreWcsId);
        setLoadStep(WcsLoad.WCS);
      } else {
        setDisplayLoading(false);
        setDisplayError(true);
      }
    }
  });

  useQuery(GET_WCS_QUERY, {
    variables: { wcsId: wcsId },
    skip: loadStep !== WcsLoad.WCS,
    onCompleted: data => {
      const controllingFarm = data?.waterConditioningSystem?.controllingFarmUuid;
      if (controllingFarm) {
        setControllingFarmId(controllingFarm);
        setLoadStep(WcsLoad.FARM);
      } else {
        setDisplayLoading(false);
        setDisplayError(true);
      }
    }
  });

  useQuery(GET_ACRE_CONFIG_V2_QUERY, {
    variables: { serialNumber: controllingFarmId },
    skip: loadStep !== WcsLoad.FARM,
    onCompleted: data => {
      const wcs = data.farmConfigurationV2?.config?.global?.wcs;
      if (wcs) {
        setWcsData(wcs);
        setLoadStep(WcsLoad.STOP);
        if (setModalData) {
          setModalData(data.farmConfigurationV2, wcsId);
        }
        setDisplayLoading(false);
      } else {
        setDisplayLoading(false);
        setDisplayError(true);
      }
    }
  });

  return (
    <div>
      {!displayLoading && displayError && <span>There was an error loading this card.</span>}
      {displayLoading && !displayError && <span>Loading...</span>}
      {!displayLoading && !displayError && (
        <span>
          <RecipeHeading>
            <div>
              <span>Type</span>
            </div>
            <div>
              <span>Set point</span>
            </div>
          </RecipeHeading>
          <DsRow className="recipe-row border">
            <div className="parameter">
              <span data-cy="AcreWaterCard___pH">pH</span>
            </div>
            <div>
              <span>{ph}</span>
            </div>
          </DsRow>
          <DsRow className="recipe-row">
            <div className="parameter">
              <span>EC</span>
            </div>
            <div>
              <span data-cy="AcreWaterCard___ec">{ec} μS</span>
            </div>
          </DsRow>
        </span>
      )}
    </div>
  );
};
