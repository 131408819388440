import { DsModal, DsButton, DsCard, DsRow } from '@infarm/design-system-react';
import {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  createElement
} from 'react';
import { CustomElement } from '../../CustomElement';
import { DetailsContaining } from './styled-components';
import { UserAction, FarmConfigurationV2 } from '@infarm/faas-middleware-types';
import { formatForCyTag } from '../utils';
// import { userCanEditRecipes } from '../../../lib/access-control';

type RecipeCardProps = {
  farmConfig: FarmConfigurationV2;
  userAction?: UserAction;
  title: string;
  editForm: string;
  modalData?: Object;
};

export const RecipeCard: FunctionComponent<RecipeCardProps> = ({
  children,
  farmConfig,
  title,
  editForm,
  userAction,
  modalData
}) => {
  const modalRef = useRef<HTMLDivElement>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userCanEditRecipesValue = false; // userCanEditRecipes();

  const updateData = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const modalForm: any = document.querySelector(editForm);
    if (modalForm && !!farmConfig) {
      modalForm.farmConfig = farmConfig;
    }
    if (modalForm && !!modalData) {
      modalForm.modalData = modalData;
    }

    return () => {
      modalForm?.removeEventListener('onRecipeApplied', closeModal);
    };
  }, [farmConfig, editForm, modalData]);

  useLayoutEffect(() => {
    const modal = modalRef?.current;
    modal?.addEventListener('stencilReactBus___recipeApplied', updateData as EventListener);

    return () => {
      modal?.removeEventListener('stencilReactBus___recipeApplied', updateData as EventListener);
    };
  }, []);

  return (
    <DsCard>
      <DetailsContaining>
        <CustomElement
          element={'recipe-card-title'}
          props={{ user: userAction?.user, updatedAt: userAction?.updatedAt, recipeHeader: title }}
        />
        {children}
      </DetailsContaining>
      {userCanEditRecipesValue && (
        <footer>
          <DsRow>
            <DsButton
              data-cy={`recipe-card___edit${formatForCyTag(title)}`}
              label="Edit"
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          </DsRow>
        </footer>
      )}
      {!!editForm && (
        <DsModal
          open={isModalOpen}
          onClosed={() => {
            setIsModalOpen(false);
          }}
          hideCloseButton={true}
        >
          {createElement(editForm, { ref: modalRef })}
        </DsModal>
      )}
    </DsCard>
  );
};
