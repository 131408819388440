import { FarmConfigurationV2, UserAction } from '@infarm/faas-middleware-types';
import { FunctionComponent, useEffect, useState } from 'react';
import { DsColumn, DsRow, DsSpacer } from '@infarm/design-system-react';
import { CustomElement } from '../CustomElement';
import styled from 'styled-components';
import { ActionKey, setObjectProperties, getUserActionForStencilElement } from './utils';
import { RecipeCard } from './cards/RecipeCard';
import { AcreWaterDetail } from './cards/AcreWaterDetail';

type AcreRecipesAndSchedulesProps = {
  serialNumber: string;
  isOnline: boolean;
  userActions: UserAction[];
  farmConfig?: FarmConfigurationV2;
};

const PlantRecipesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AcreRecipesAndSchedules: FunctionComponent<AcreRecipesAndSchedulesProps> = ({
  isOnline,
  userActions,
  farmConfig
}) => {
  const [wcsFarmConfig, setWcsFarmConfig] = useState<FarmConfigurationV2>();
  const [wcsId, setWcsId] = useState<string>();
  useEffect(() => {
    if (farmConfig) {
      const stencilElements = [
        {
          elementName: 'acre-co2-container',
          actionKey: ActionKey.Co2
        },
        {
          elementName: 'acre-temperature-humidity-container',
          actionKey: ActionKey.AcreCrac
        }
      ];
      stencilElements.forEach(element => setObjectProperties(element, { farmConfig, userActions }));
      const plantRecipes: any = document.querySelector('plant-recipes');
      if (plantRecipes && !!farmConfig) {
        plantRecipes.userActions = userActions;
        plantRecipes.farmConfig = farmConfig;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmConfig, userActions]);

  const setLoadedWcsFarmConfig = (waterFarmConfig: FarmConfigurationV2, farmWcsId: string) => {
    setWcsFarmConfig(waterFarmConfig);
    setWcsId(farmWcsId);
  };

  return (
    <DsColumn>
      <DsRow>
        <h2>Global recipes</h2>
      </DsRow>
      {farmConfig && (
        <>
          <DsRow>
            <RecipeCard
              userAction={getUserActionForStencilElement(
                { userActions, farmConfig: farmConfig },
                ActionKey.Water
              )}
              farmConfig={farmConfig}
              title={'Water recipe'}
              editForm={'acre-water-recipe-form'}
              modalData={{ wcsFarmConfig, wcsId }}
            >
              <AcreWaterDetail farmConfig={farmConfig} setModalData={setLoadedWcsFarmConfig} />
            </RecipeCard>
            <CustomElement
              element={'acre-co2-container'}
              props={{
                isOnline,
                recipeCardTitle: 'CO2 Recipe'
              }}
            />
          </DsRow>
          <DsRow>
            <CustomElement
              element={'acre-temperature-humidity-container'}
              props={{
                isOnline,
                recipeCardTitle: 'Temperature & humidity recipe'
              }}
            />
            <DsSpacer></DsSpacer>
          </DsRow>
          <DsRow>
            <PlantRecipesHeader>
              <h2>Plant recipes</h2>
            </PlantRecipesHeader>
          </DsRow>
          <CustomElement
            element={'plant-recipes'}
            props={{
              isOnline
            }}
          />
        </>
      )}
    </DsColumn>
  );
};
